<template>
  <div>
    <vx-card title="Mail Relance Projet en Cours" class="mb-4">
      <div slot="no-body">
        <div class="aps-benin-v2-content">
          <div>
            <vs-tabs>
              <vs-tab label="SOHU">
                <div>
                  <aps-benin-v2-mails
                    name="admin_Setting_MailRelaunchCurrentProject_sohu"
                  >
                  </aps-benin-v2-mails>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>





<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import global from "@/config/global";
import { mixin } from "@/mixins/mixin";

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      role: {},
    };
  },
  created() {
    Validator.localize("fr", VeeValidateFR);
    this.global = global;
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_mail_client
    ) {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user", "userId"]),
  },
  methods: {},
};
</script>

<style></style>
